.mainText {
  text-align: center;
}

/* sizes */
.textXxxl {
  font-size: var(--font-size-xxxl);
}

.textXxl {
  font-size: var(--font-size-xxl);
}

.textXl {
  font-size: var(--font-size-xl);
}

.textLg {
  font-size: var(--font-size-lg);
}

.textMd {
  font-size: var(--font-size-md);
}

.textSm {
  font-size: var(--font-size-sm);
}

.textXs {
  font-size: var(--font-size-xs);
}

.textXxs {
  font-size: var(--font-size-xxs);
  text-decoration: none;
}

.textXxxs {
  font-size: var(--font-size-xxxs);
}

/* weights */
.textW300 {
  font-weight: 300;
}

.textW400 {
  font-weight: 400;
}

.textW500 {
  font-weight: 500;
}

.textW600 {
  font-weight: 600;
}

.textW700 {
  font-weight: 700;
}

.initialQuestionMark {
  font-family: 'Roboto';
  font-weight: 100;
  position: relative;
  top: -1px;
  left: -2px;
}

.endingQuestionMark {
  font-family: 'Roboto';
  font-weight: 100;
  position: relative;
  left: 2px;
}
