.navigation {
  display: flex !important;
  background-color: var(--bar-background);
  justify-content: space-around !important;
  height: 90px;
  box-shadow: 1px 0 10px 0 rgb(89 98 115 / 20%);
  margin-bottom: 0;
  width: 100%;
  flex-direction: row;
  padding-right: 35px;
}

.navImage {
  flex: 1 1;
  padding: 35px;
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.logoImg {
  cursor: pointer;
}

.navContainer {
  display: flex;
  align-items: center;
}

.imageLogo {
  width: 15% !important;
  height: auto !important;
}