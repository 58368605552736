:root {
  --font-family: 'ChampagneLimousines';

  --font-size-xxxs: 12px;
  --font-size-xxs: 14px;
  --font-size-xs: 16px;
  --font-size-sm: 18px;
  --font-size-md: 22px;
  --font-size-lg: 28px;
  --font-size-xl: 35px;
  --font-size-xxl: 42px;
  --font-size-xxxl: 48px;
}