.mainButton {
  background: var(--ternary-color);
  color: var(--white-color);
  border: 0 solid transparent;
  padding: 8px 20px;
  justify-content: center;
  align-items: center;
  text-align: center;
  text-decoration: none;
}

.mainButton:hover {
  background: var(--primary-color) !important;
  color: var(--black-color) !important;
  transition: 0.4s;
  text-decoration: none;
  box-shadow: 1px 0 10px 0 rgb(89 98 115 / 60%);
}

.secondaryButton {
  background: var(--primary-color);
  color: var(--black-color);
  box-shadow: 1px 0 10px 0 rgb(89 98 115 / 60%);
}

.secondaryButton:hover {
  background: var(--secondary-color) !important;
  color: var(--black-color) !important;
  background-image: linear-gradient(60deg, var(--secondary-yellow-color) 60%, var(--secondary-green-color) 100%) !important;
}

/* sizes */
.buttonXs {
  width: 90px;
  font-size: var(--font-size-xxs);
  color: var(--white-color);
  font-weight: 400;
  height: 40px;
  text-decoration: none;
  box-shadow: none;
}

.buttonXs:hover {
  box-shadow: none;
}

.buttonSm {
  width: 160px;
  font-size: var(--font-size-xxs);
  font-weight: 400;
  height: 40px;
  text-decoration: none;
}

.buttonMd {
  padding: 15px 23px;
  font-size: var(--font-size-sm);
  line-height: 24px;
  font-weight: 600;
  text-decoration: none;
}

.buttonLg {
  padding: 20px 80px;
  font-size: var(--font-size-md);
  font-weight: 600;
  text-decoration: none;
}

.buttonXl {
  padding: 25px 100px;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
}

.buttonXxl {
  padding: 34px 120px;
  font-size: var(--font-size-xxl);
  text-transform: uppercase;
  font-weight: 700;
}
