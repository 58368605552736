:root {
  --primary-color: #c7eafa;
  --secondary-color: #8f22bc;
  --ternary-color: #26235e;

  --secondary-yellow-color: #fec46f;
  --secondary-green-color: #dde20e;


  --black-color: #000000;
  --white-color: #ffffff;
  --grey-color: #939598;

  --border-input-color: #d9d9d9;

}