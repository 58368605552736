@import './colors.scss';
@import './theme.scss';

// Champagne Limousines
@font-face {
  font-family: 'ChampagneLimousines';
  src: url('../assets/fonts/champagne_limousines/Champagne & Limousines.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'ChampagneLimousines';
  src: url('../assets/fonts/champagne_limousines/Champagne & Limousines Bold.ttf');
  font-weight: 600;
  font-style: normal;
}

// Gotham Rounded
@font-face {
  font-family: 'GothamRounded';
  src: url('../assets/fonts/gotham-rounded/GothamRoundedLight_21020.ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded';
  src: url('../assets/fonts/gotham-rounded/GothamRounded-Medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GothamRounded';
  src: url('../assets/fonts/gotham-rounded/GothamRoundedBold_21016.ttf');
  font-weight: 600;
  font-style: normal;
}

body {
  position: relative;
  margin: 0px;
  background-color: var(--white-color);
  font-family: 'GothamRounded';
  font-size: var(--font-size-md);
  overflow-anchor: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'ChampagneLimousines';
}

.layout-container {
  .nav-items {
    border: 0px;
    display: inline-block;
  }
  .p-submenu-icon.pi.pi-angle-down {
    display: none;
  }
  .p-menuitem-text {
    overflow: visible;
    font-family: Extatica, sans-serif;
    letter-spacing: -0.04px;
    color: var(--ternary-grey-color);
    font-size: 16px;
    line-height: 20px;
  }
}

li.pt-list {
  list-style: none !important;
}

li.pt-list::before {
  content: '\00BB';
  color: var(--white-color);
  margin-right: 0.7rem;
  font-size: 25px;
  font-weight: 600;
}

li.pt-list.pro::before {
  color: var(--ternary-color);
}

li.pt-list.sublist::before {
  content: '\1405';
  font-size: 15px;
  font-weight: 600;
}

.p-menubar .p-submenu-list {
  z-index: 10;
}

.primary-divider {
  margin: 1.3rem 0 2rem;
  &.p-divider.p-divider-horizontal:before {
    border-top: solid 1px var(--ternary-color);
  }
}

.p-tooltip {
  background-color: var(--white-color) !important;
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  width: 20%;
}

.p-tooltip-text {
  background-color: var(--white-color) !important;
  color: var(--black-color) !important;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 0 0.6rem !important;
  max-width: 450px;
  h3 {
    color: black;
    line-height: 16px;
    font-weight: 400;
    letter-spacing: 0.1px;
    font-family: inherit;
    font-size: 14.5px;
    text-align: start;
  }
}

.p-accordion .p-accordion-tab .p-accordion-content {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  display: flex;
  flex-flow: wrap;
  padding: 30px;
  align-items: center;
}

.p-dropdown .p-inputtext {
  border: 0 none;
  align-self: center;
}

.table-icon {
  font-size: 20px;
  padding: 10px 12px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
}

.table-icon:hover {
  color: var(--primary-color) !important;
}